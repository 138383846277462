<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol md="8">
                <h4>
                  Estimasi Status Permohonan
                  {{ is_pemasukan ? "Pemasukan" : "Pengeluaran" }}
                  {{ kategori }}
                </h4>
                <!-- <small>{{ tpb_nama }}</small> -->
              </CCol>
            </CRow>
          </CCardHeader>
          <CForm v-on:submit.prevent="storeProcess" class="fwdwd">
            <CCardBody>
              <table class="table table-sm table-bordered">
                <thead class="bg-dark text-white">
                  <tr>
                    <th>No</th>
                    <th>Status</th>
                    <th>Role</th>
                    <th>Estimasi (dalam jam)</th>
                  </tr>
                </thead>
                <tbody>
                  <template>
                    <tr v-if="loadingTable">
                      <td colspan="7">
                        <i class="fa fa-spin fa-refresh"></i> Loading...
                      </td>
                    </tr>
                    <tr
                      v-for="(
                        row_data, key_status_permohonan
                      ) in permohonan_status"
                      :key="key_status_permohonan"
                      v-else
                    >
                      <td>
                        {{ key_status_permohonan + 1 }}
                      </td>
                      <td>
                        {{ row_data.status }}
                      </td>
                      <td>
                        {{ row_data.role }}
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control"
                          v-model="row_data.estimasi"
                        />
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
              <span class="text-danger">
                * isikan hanya angka, contoh : 1 sama dengan 1jam<br />
                * Jika 1 hari maka 24
              </span>
            </CCardBody>
            <CCardFooter>
              <div class="text-right">
                <button
                  type="submit"
                  class="btn btn-primary text-white btn-hover-outline-primary"
                >
                  <i class="fa fa-save"></i> Simpan
                </button>
              </div>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
const null_array = [];
export default {
  name: "SetEstimasiProses",
  data() {
    return {
      is_pemasukan: this.$route.params.is_pemasukan,
      kategori: this.$route.params.kategori,
      permohonan_status: [],
      loadingTable: true,
    };
  },
  methods: {
    storeProcess: function () {
      Swal.fire({
        title: '<i class="fa fa-refresh fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        // allowOutsideClick: false,
      });
      var FormData = require("form-data");
      var data = new FormData();
      data.append("permohonan_status", JSON.stringify(this.permohonan_status));
      var config = {
        method: "post",
        url: this.apiLink + "api/crud/estimasi-waktu/update_estimasi",
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
        data: data,
      };
      axios(config).then(function (response) {
        var res_ajukan = response.data;
        var res_ajukan_data = res_ajukan.data;
        if (res_ajukan.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_ajukan.data.message,
          });
        } else {
          let timerInterval;
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Estimasi waktu Berhasil Disimpan.",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
            didOpen: () => {
              timerInterval = setInterval(() => {
                const content = Swal.getContent();
                if (content) {
                  const b = content.querySelector("b");
                  if (b) {
                    b.textContent = Swal.getTimerLeft();
                  }
                }
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              // window.location.reload();
              self.$router.push({ name: "AllModule" });
            }
          });
        }
      });
    },
  },
  created() {
    Swal.fire({
      title: '<i class="fa fa-refresh fa-spin"></i>',
      text: "Loading...",
      showConfirmButton: false,
      // allowOutsideClick: false,
    });
    this.loadingTable = true;
    axios
      .get(this.apiLink + "api/crud/estimasi-waktu/set_estimasi", {
        params: {
          kategori: this.kategori,
          is_pemasukan: this.is_pemasukan,
        },
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var result = response.data;
        var status_permohonan_data = result.data[0];

        if (result.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result.data.message,
          });
        } else {
          swal.close();
          let clear_data = [];
          $.each(
            status_permohonan_data,
            function (indexInArray, valueOfElement) {
              clear_data.push({
                id: valueOfElement.id,
                status: valueOfElement.st_nama,
                role: valueOfElement.roles.name,
                estimasi: valueOfElement.estimasi_hari,
              });
            }
          );
          console.log("data status: ", clear_data);
          this.loadingTable = false;
          this.permohonan_status = clear_data;
        }
      });
  },
};
</script>
